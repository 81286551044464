import React, { useState, useEffect } from "react"
import { navigate } from '@reach/router';

import Layout from "../components/layout"
import SEO from "../components/seo"
import {getUser, initAuth, isLoggedIn} from "../app/services/auth";
import SpotifyWebApi from "spotify-web-api-js";

const isBrowser = () => typeof window !== 'undefined'

const ListenPage = () => {
    const [current, setCurrent] = useState({ item: { name: 'None', album: { name: 'None' }, artists: [ { name: 'None' } ] } } )
    const [rating, setRating] = useState('Not Set')

    useEffect(() => {
        window.history.replaceState({}, document.title, "/listen")
    })

    if (isBrowser()) {
        initAuth()

        const params = new URLSearchParams(window.location.search)
        const code = params.get('code')
        const state = params.get('state')

        if (state) {
            if (state !== window.localStorage.getItem('oauth_nonce'))
                return (<Layout><h2>Mismatch: Unexpected nonce</h2></Layout>)

            window.localStorage.removeItem('oauth_nonce')
            console.log("Source: " + state.split('-')[0])
        }

        if (code)
            window.localStorage.setItem('spotify_refresh_token', code)

        if (isLoggedIn() && current.item.name == 'None') {
            const spotifyApi = new SpotifyWebApi()
            const spotify_access_token = window.localStorage.getItem('spotify_access_token')
            spotifyApi.setAccessToken(spotify_access_token)
            spotifyApi.getMyCurrentPlayingTrack()
                .then(function (data) {
                    console.log('Currently playing', data)
                    setCurrent(data)
                }, function (err) {
                    const response = JSON.parse(err.response)
                    if (response.error.status == 401) {
                        const user = getUser()
                        const refresh_token = window.localStorage.getItem('spotify_refresh_token')
                        console.log("Refresh token using " + refresh_token + " for " + user)
                        fetch('/.netlify/functions/spotifyLoginLambda?token=' + refresh_token, {
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + user.token.access_token,
                            },
                        })
                            .then(response => response.json())
                            .then((json) => {
                                const access_token = json.token.access_token
                                const refresh_token = json.token.refresh_token
                                window.localStorage.setItem('spotify_access_token', access_token)
                                window.localStorage.setItem('spotify_refresh_token', refresh_token)
                                setCurrent({item: {name: 'Pending'}})
                            })
                    } else
                        console.error((err))
                })
        }
    }

    return (
        <Layout>
            <SEO title="Listen" />
            <h1>Current Track</h1>
            <p>Title: {current.item.name}</p>
            <p>Album: {current.item.album.name}</p>
            <p>Artist: {current.item.artists[0].name}</p>
            <p>Progress: {current.progress_ms/1000.0} / {current.item.duration_ms/1000.0}</p>
            <Rating rating="Hate" setRating={setRating}/>
            <Rating rating="Pass" setRating={setRating}/>
            <Rating rating="OK" setRating={setRating}/>
            <Rating rating="Good" setRating={setRating}/>
            <Rating rating="Great" setRating={setRating}/>
            <Rating rating="Must See" setRating={setRating}/>
            <p>Rating: {rating}</p>
        </Layout>
    )
}

const Rating = ({rating, setRating}) => {
    return (
        <button onClick={()=>{
            console.log("Call to save currently playing track with rating: " + rating)
            setRating(rating)
        }}>{rating}</button>
    )
}

export default ListenPage
